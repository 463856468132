import isStringWithoutNumbersOrSpecialCharacters from 'validators/isStringWithoutNumbersOrSpecialCharacters';
import isStringOnlyLettersAndNumbers from 'validators/isStringOnlyLettersAndNumbers';
import { validateTravelDocumentExpiry, validateTravelDocumentIssued } from './validateTravelDocumentInputs';
import * as inputNames from '../constants/inputNames';
import validateRewardNumber from './rewardNumber';
import isStringOnlyWithLatinLetters from 'validators/isStringOnlyWithLatinLetters';
import { enforceLattinLettersMessage } from 'constants/validationMessages';

const date = new Date();
const currentYear = date.getFullYear();

export default function validateInputValue(name, value, required, additionalData = {}) {
  const { airlineWhitelabel, validationData, returnDate } = additionalData;
  let valid;

  if (required && !value) {
    return {
      valid: false,
      message: {
        id: 'missingValue.tooltip',
        description: 'Please fill in this field',
        defaultMessage: 'Please fill in this field',
      },
      clearOnFocus: true,
    };
  }

  switch (name) {
    case inputNames.FIRST_NAME:
      valid = isStringWithoutNumbersOrSpecialCharacters(value);

      if (!valid) {
        return {
          valid,
          message: !valid
            ? {
                id: 'firstNameInput.invalid.tooltip',
                description: "Names can't contain numbers or special characters",
                defaultMessage: "Names can't contain numbers or special characters",
              }
            : null,
        };
      }

      if (value) {
        valid = value !== (validationData && validationData[inputNames.LAST_NAME]);
        if (!valid) {
          return {
            valid,
            message: !valid && {
              id: 'firstNameLastNameIdentical',
              description: 'First name and last name cannot be identical.',
              defaultMessage: 'First name and last name cannot be identical.',
            },
          };
        }
        if (additionalData && additionalData.shouldForceLatinLetters) {
          valid = isStringOnlyWithLatinLetters(value);
          if (!valid) {
            return {
              valid,
              message: enforceLattinLettersMessage,
            };
          }
        }
      }

      valid = value.length <= 20;
      return {
        valid,
        message: !valid
          ? {
              id: 'nameInputToLong.invalid.tooltip',
              description: "Names can't exceed {number} characters",
              defaultMessage: "Names can't exceed {number} characters",
              values: { number: 20 },
            }
          : null,
      };

    case inputNames.LAST_NAME:
      valid = isStringWithoutNumbersOrSpecialCharacters(value);

      if (!valid) {
        return {
          valid,
          message: !valid
            ? {
                id: 'lastNameInput.invalid.tooltip',
                description: "Names can't contain numbers or special characters",
                defaultMessage: "Names can't contain numbers or special characters",
              }
            : null,
        };
      }
      if (value) {
        valid = value !== (validationData && validationData[inputNames.FIRST_NAME]);
        if (!valid) {
          return {
            valid,
            message: !valid && {
              id: 'firstNameLastNameIdentical',
              description: 'First name and last name cannot be identical.',
              defaultMessage: 'First name and last name cannot be identical.',
            },
          };
        }
        if (additionalData && additionalData.shouldForceLatinLetters) {
          valid = isStringOnlyWithLatinLetters(value);
          if (!valid) {
            return {
              valid,
              message: enforceLattinLettersMessage,
            };
          }
        }
      }
      valid = value.length <= 20;
      return {
        valid,
        message: !valid
          ? {
              id: 'nameInputToLong.invalid.tooltip',
              description: "Names can't exceed {number} characters",
              defaultMessage: "Names can't exceed {number} characters",
              values: { number: 20 },
            }
          : null,
      };

    case inputNames.DATE_OF_BIRTH:
      valid = value && value.isValid && value.isValid();
      return {
        valid,
        message: !valid && {
          id: 'yearInput.invalid.tooltip',
          description: `Year should be a number between 1900 and ${currentYear}`,
          defaultMessage: `Year should be a number between 1900 and ${currentYear}`,
        },
      };

    case inputNames.REWARD_NUMBER:
      return validateRewardNumber(value, airlineWhitelabel);

    case inputNames.TRAVEL_DOCUMENT_NUMBER:
      valid = isStringOnlyLettersAndNumbers(value);
      if (!valid) {
        return {
          valid,
          message: !valid
            ? {
                id: 'travelDocumentNumber.invalid.string.tooltip',
                description: "Travel document number can't contain special characters",
                defaultMessage: "Travel document number can't contain special characters",
              }
            : null,
        };
      }

      valid = value && value.length <= 20;

      return {
        valid,
        message: !valid
          ? {
              id: 'travelDocumentNumber.invalid.stringTooLong.tooltip',
              description: "Travel document number can't exceed {number} characters",
              defaultMessage: "Travel document number can't exceed {number} characters",
              values: { number: 20 },
            }
          : null,
      };

    case inputNames.TRAVEL_DOCUMENT_EXPIRATION:
      valid = value && value.isValid && value.isValid && validateTravelDocumentExpiry(value, returnDate);
      return {
        valid,
        message: !valid
          ? {
              id: 'travelDocumentExpiration.invalid.tooltip',
              description: 'Travel document must be valid at the time of the trip',
              defaultMessage: 'Travel document must be valid at the time of the trip',
            }
          : null,
      };

    case inputNames.TRAVEL_DOCUMENT_ISSUED:
      valid = value && value.isValid && value.isValid && validateTravelDocumentIssued(value);
      return {
        valid,
        message: !valid
          ? {
              id: 'travelDocumentIssued.invalid.tooltip',
              description: 'Travel document must have an issue date prior to today',
              defaultMessage: 'Travel document must have an issue date prior to today',
            }
          : null,
      };

    case inputNames.TRAVEL_DOCUMENT_ISSUING_CITY:
      valid = isStringWithoutNumbersOrSpecialCharacters(value);

      return {
        valid,
        message: !valid
          ? {
              id: 'cityInput.invalid.tooltip',
              description: "City names can't contain numbers or special characters",
              defaultMessage: "City names can't contain numbers or special characters",
            }
          : null,
      };

    default:
      return {
        valid: true,
        message: null,
      };
  }
}
