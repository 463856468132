import * as types from './types';

export const setHeaderRef = (ref) => ({
  type: types.SET_HEADER_REF,
  payload: {
    ref,
  },
});

export const setHeaderHeight = (height) => ({
  type: types.SET_HEADER_HEIGHT,
  payload: {
    height,
  },
});

export const setFiltersBarHeight = (height) => ({
  type: types.SET_FILTERS_BAR_HEIGHT,
  payload: {
    height,
  },
});
export const setThirdPartyHeaderHeight = (height) => ({
  type: types.SET_THIRD_PARTY_HEADER_HEIGHT,
  payload: {
    height,
  },
});

export const setTravelSummaryBarHeight = (height) => ({
  type: types.SET_TRAVEL_SUMMARY_BAR_HEIGHT,
  payload: {
    height,
  },
});

export const setVirtuosoRef = (ref) => ({
  type: types.SET_VIRTUOSO_REF,
  payload: {
    ref,
  },
});
