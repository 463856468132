import * as types from './types';

const initialState = {
  header: null,
  headerHeight: 0,
  filtersBarHeight: 0,
  thirdPartyHeaderHeight: 0,
  filtersListMenuHeight: 0,
  travelSummaryBarHeight: 0,
  virtuosoRef: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_HEADER_REF:
      state = setHeaderRef(state, action.payload);
      break;

    case types.SET_HEADER_HEIGHT:
      state = setHeaderHeight(state, action.payload);
      break;

    case types.SET_FILTERS_BAR_HEIGHT:
      state = setFiltersBarHeight(state, action.payload);
      break;

    case types.SET_THIRD_PARTY_HEADER_HEIGHT:
      state = setThirdPartyHeaderHeight(state, action.payload);
      break;

    case types.SET_TRAVEL_SUMMARY_BAR_HEIGHT:
      state = setTravelSummaryBarHeight(state, action.payload);
      break;

    case types.SET_VIRTUOSO_REF:
      state = setVirtuosoRef(state, action.payload);
      break;

    default:
      break;
  }

  return state;
};

export default reducer;

// Private functions
const setHeaderRef = (state, payload) => ({
  ...state,
  header: payload.ref,
});

const setHeaderHeight = (state, payload) => ({
  ...state,
  headerHeight: payload.height,
});

const setFiltersBarHeight = (state, payload) => ({
  ...state,
  filtersBarHeight: payload.height,
});

const setThirdPartyHeaderHeight = (state, payload) => ({
  ...state,
  thirdPartyHeaderHeight: payload.height,
});

const setTravelSummaryBarHeight = (state, payload) => ({
  ...state,
  travelSummaryBarHeight: payload.height,
});

const setVirtuosoRef = (state, payload) => ({
  ...state,
  virtuosoRef: payload.ref,
});
