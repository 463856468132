import getKeyValuesFromObject from 'utils/array/getKeyValuesFromObject';
import getChargeablePersons from 'state/checkoutPage/packagesSearch/selectors/getChargeablePersons';
import getTransferByProductToken from 'state/checkoutPage/transfers/selectors/getTransferByProductToken';
import { getInsuranceByProductToken } from 'state/checkoutPage/insurances/selectors';
import getCancellationProtectionByProductToken from 'state/checkoutPage/cancellationProtections/selectors/getCancellationProtectionByProductToken';
import getVehicleRentalsByProductTokens from 'state/checkoutPage/vehicleRental/selectors/getVehicleRentalsByProductTokens';
import getVehicleRentalsPrice from 'state/checkoutPage/vehicleRental/selectors/getVehicleRentalsPrice';
import { getCO2CompensationPrice } from 'state/checkoutPage/co2Compensation/selectors';
import { getSelectedEarthToday } from 'state/checkoutPage/additionalServices/selectors/getSelectedEarthToday';
import calculatePriceForHotelsAndFlights from './calculatePriceForHotelsAndFlights';
import getSelectedExtras from './getSelectedExtras';

export default function calculateTotalPrice() {
  return (dispatch, getState) => {
    const {
      checkoutPageTransfers,
      checkoutPageInsurances,
      checkoutPageCancellationProtections,
      checkoutPageVehicleRental,
      checkoutPageTravelSummary,
      checkoutPageFlightsDetails,
      checkoutPageAdditionalServices,
    } = getState();
    const {
      transfer,
      vehicleRentals: _vehicleRentals,
      insurance,
      cancellationProtection,
      co2Compensation,
      earthToday,
    } = checkoutPageTravelSummary.selectedOtherExtras;
    const transfers = checkoutPageTransfers.transfers;
    const insurances = checkoutPageInsurances.insurances;
    const cancellationProtections = checkoutPageCancellationProtections.cancellationProtections;
    const vehicleRentals = checkoutPageVehicleRental.value && checkoutPageVehicleRental.value.carRentals;
    const additionalServices = checkoutPageAdditionalServices.additionalServices;
    const chargeablePersons = dispatch(getChargeablePersons());

    let totalPrice = dispatch(calculatePriceForHotelsAndFlights());

    const selectedFlightsExtrasPrices = getKeyValuesFromObject(dispatch(getSelectedExtras()), 'price', true);
    const selectedFlightsExtrasTotalPrice = selectedFlightsExtrasPrices.reduce(addPrices, 0);
    totalPrice += selectedFlightsExtrasTotalPrice;

    const selectedTransfer = getTransferByProductToken(transfers, transfer);
    if (selectedTransfer) {
      totalPrice += selectedTransfer.prices.total.amount;
    }

    const selectedVehicleRentals = getVehicleRentalsByProductTokens(vehicleRentals, _vehicleRentals);
    if (selectedVehicleRentals) {
      const vehicleRentalsTotalPrice = getVehicleRentalsPrice(selectedVehicleRentals);
      totalPrice += parseInt(vehicleRentalsTotalPrice);
    }

    const selectedInsurance = getInsuranceByProductToken(insurances, insurance);
    if (selectedInsurance) {
      totalPrice += parseInt(selectedInsurance.total);
    }

    const co2CompensationPrice = getCO2CompensationPrice(co2Compensation);
    totalPrice += parseInt(co2CompensationPrice);

    const earthTodayPrice = getSelectedEarthToday(earthToday, additionalServices);
    if (earthTodayPrice) {
      totalPrice += parseInt(earthTodayPrice.price);
    }

    const selectedCancellationProtection = getCancellationProtectionByProductToken(
      cancellationProtections,
      cancellationProtection
    );
    if (selectedCancellationProtection) {
      totalPrice += parseInt(selectedCancellationProtection.total);
    }

    let pricePerPerson = calculatePricePerPerson(totalPrice, chargeablePersons);

    if (
      checkoutPageFlightsDetails.changedUpgradePrice &&
      checkoutPageFlightsDetails.changedUpgradePrice.changed &&
      checkoutPageFlightsDetails.changedUpgradePrice.newPriceAccepted
    ) {
      totalPrice += checkoutPageFlightsDetails.changedUpgradePrice.price;
      pricePerPerson = calculatePricePerPerson(totalPrice, chargeablePersons);
    }

    return { totalPrice, pricePerPerson };
  };
}

function addPrices(accumulator, currentValue) {
  return accumulator + currentValue;
}

function calculatePricePerPerson(totalPrice, chargeablePersons) {
  return Math.round(totalPrice / chargeablePersons);
}
