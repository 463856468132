import * as eventsNames from './constants/eventsNames';
import trackEvent from './utils/trackEvent';
import hotelLiveSearch from './events/hotelLiveSearch';
import searchResultsLoaded from './events/searchResultsLoaded';
import hotelPageRoomsViewed from './events/hotelPageRoomsViewed';
import singleHotelSearch from './events/singleHotelSearch';
import addedToCart from './events/addedToCart';
import loadedCheckoutPage from './events/loadedCheckoutPage';
import bookingSuccessful from './events/bookingSuccessful';
import abandonedCart from './events/abandonedCart.js';
import purchase from './events/purchase';
import fbPurchase from './events/fbPurchase';
import checkout from './events/checkoutSteps';
import viewItem from './events/viewItem';
import addToCart from './events/addToCart';

export default function executeTrackingEvent(eventName, eventData) {
  return (dispatch, getState) => {
    try {
      switch (eventName) {
        case eventsNames.SEARCH_BOX_SUBMIT:
        case eventsNames.SEARCH_RESULTS:
        case eventsNames.SEARCH_RESULTS_NO_RESULTS:
        case eventsNames.CHECKOUT_NO_RESULTS:
        case eventsNames.SEARCH_RESULTS_FILTERED:
        case eventsNames.ROOM_BOARD_CHANGED:
        case eventsNames.NOT_FOUND:
        case eventsNames.SEARCH_PAGE:
        case eventsNames.SELECT_ROOMS:
        case eventsNames.FLIGHT_SELECTION:
        case eventsNames.FLIGHT_EXTRAS:
        case eventsNames.OTHER_EXTRAS:
        case eventsNames.PERSONAL_DATA:
        case eventsNames.CONFIRMATION:
        case eventsNames.CLICKED_ADVERTS:
        case eventsNames.CLICKED_PRICE_BARS:
        case eventsNames.CLICKED_MOST_POPULAR_HOTELS:
        case eventsNames.CLICKED_ABANDONED_CART:
        case eventsNames.CLICKED_HOME_BOXES:
        case eventsNames.CLICKED_FLIGHTS_FILTERS:
        case eventsNames.CLICKED_HOTEL_PAGE_SEARCH:
        case eventsNames.CLICKED_ADD_TO_FAVORITES:
        case eventsNames.CLICKED_SHARE_BUTTON:
        case eventsNames.FB_FIND_LOCATION:
        case eventsNames.FB_VIEW_CONTENT:
        case eventsNames.FB_ADD_TO_CART:
        case eventsNames.CLICKED_FLIGHT:
        case eventsNames.CLICKED_SHOW_MORE_FLIGHTS:
        case eventsNames.CLICKED_SEARCH:
        case eventsNames.LOAD_MORE_PACKAGES_SEARCH_PAGE:
        case eventsNames.FLIGHTS_DETAILS_OFFER_EXPIRED:
        case eventsNames.SHARE_MY_FAVORITES_HOTELS:
        case eventsNames.CLICKED_MY_FAVORITES:
          trackEvent(eventName, eventData);
          break;

        case eventsNames.HOTEL_LIVE_SEARCH:
          hotelLiveSearch(eventName, eventData);
          break;

        case eventsNames.SEARCH_RESULTS_LOADED:
          searchResultsLoaded(eventName, eventData);
          break;

        case eventsNames.HOTEL_PAGE_ROOMS_VIEWED:
          hotelPageRoomsViewed(eventName, eventData);
          break;

        case eventsNames.SINGLE_HOTEL_SEARCH:
          singleHotelSearch(eventName, eventData);
          break;

        case eventsNames.ADDED_TO_CART:
          addedToCart(eventName, eventData, getState(), dispatch);
          break;

        case eventsNames.LOADED_CHECKOUT_PAGE:
          loadedCheckoutPage(eventName, eventData, getState(), dispatch);
          break;

        case eventsNames.BOOKING_SUCCESSFUL:
          bookingSuccessful(eventName, eventData, getState(), dispatch);
          break;

        case eventsNames.FB_PURCHASE:
          fbPurchase(eventName, eventData, getState(), dispatch);
          break;

        case eventsNames.Checkout:
          checkout(eventName, eventData, getState(), dispatch);
          break;

        case eventsNames.PURCHASE:
          purchase(eventName, eventData, getState(), dispatch);
          break;
        case eventsNames.VIEW_ITEM:
          viewItem(eventName, getState());
          break;
        case eventsNames.ADD_TO_CART:
          addToCart(eventName, getState(), dispatch);
          break;
        case eventsNames.ABANDONED_CART:
          abandonedCart(eventName, getState(), dispatch);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`Error on executeTrackingEvent`, error);
      window.Rollbar.error(`Error on executeTrackingEvent`, error);
    }
  };
}
