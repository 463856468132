import * as types from './types';

export function setIsFetchingSearchResults(fetching) {
  return {
    type: types.SET_IS_FETCHING_SEARCH_RESULTS,
    payload: {
      fetching,
    },
  };
}

export function setIsFilteringSearchResults(filtering) {
  return {
    type: types.SET_IS_FILTERING_SEARCH_RESULTS,
    payload: {
      filtering,
    },
  };
}

export function evalShowLoader() {
  return {
    type: types.EVAL_SHOW_LOADER,
  };
}

export function setCanHideLoader(value) {
  return {
    type: types.SET_CAN_HIDE_LOADER,
    payload: {
      value,
    },
  };
}

export function fetchSearchResults() {
  return {
    type: types.FETCH_SEARCH_RESULTS,
  };
}

export function fetchSearchResultsFromQuery() {
  return {
    type: types.FETCH_SEARCH_RESULTS_FROM_QUERY,
  };
}

export function filterSearchResults(typeOfAction) {
  return {
    type: types.FILTER_SEARCH_RESULTS,
    payload: {
      typeOfAction,
    },
  };
}

export function setSearchResults(results, hotels, showInfoMessage, sortBy) {
  return {
    type: types.SET_SEARCH_RESULTS,
    payload: {
      results,
      hotels,
      showInfoMessage,
      sortBy,
    },
  };
}

export function updateHotelsInfo(hotels) {
  return {
    type: types.UPDATE_HOTELS_INFO,
    payload: {
      hotels,
    },
  };
}

export function scrollPackageIntoView(hotelId) {
  return {
    type: types.SCROLL_PACKAGE_INTO_VIEW,
    payload: {
      hotelId,
    },
  };
}

export function sortBySearchResult(value) {
  return {
    type: types.SORT_BY_SEARCH_RESULTS,
    payload: {
      value,
    },
  };
}

export function filterBySearchResults(type, value, sortBy) {
  return {
    type: types.FILTER_BY_SEARCH_RESULTS,
    payload: {
      type,
      value,
      sortBy,
    },
  };
}

export function setShowNoResultsFoundPage(value) {
  return {
    type: types.SET_SHOW_NO_RESULTS_FOUND_PAGE,
    payload: {
      value,
    },
  };
}
