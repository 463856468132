// Note: The colors should be used through the theme itself wherever possible,
// ex: ${props => props.theme.colors.primary.dark};
// They are exported because i could not access them that way when styling the 'react-select' components.
export const colors = {
  primary: {
    default: '#f4e5d3',
    light: '#fff9f2',
    dark: '#59544d',
  },
  secondary: {
    default: '#0a9494',
    light: '#3ab8b8',
    dark: '#087070',
  },
  adjacent: {
    default: '#faa340',
    defaultTransparent: 'rgba(250, 164, 66, 0.5)',
    dark: '#eb8c21',
  },
  text: {
    default: '#5a554e',
    light: '#8a8176',
    light_v2: '#a09d99',
    dark: '#000000',
    darkTransparent: 'rgba(0, 0, 0, 0.5)',
    darkGrey: '#333',
    secondary: '#6b665f',
    white: '#ffffff',
    disabled: '#d3d3d3',
    templates: {
      header: '#302e2a',
      secondary: '#8a8176',
    },
    price: '#faa340',
    grey: '#bdbdbd',
  },
  background: {
    light: '#ffffff',
    dark: '#000000',
    disabled: '#efefef',
    placeholder: '#cdcdcd',
    hover: '#f8f8f8',
    airBalticBackground: '#CDDA32',
    level: {
      success: ['#e9fce3', '#309510'],
      info: ['#e3f7fc', '#107b95'],
      warning: ['#fcf8e3', '#958010'],
      danger: ['#fce3e3', '#951010'],
    },
    severity: {
      error: '#d74545',
    },
    grey: '#e7e7e7',
    greyLight: '#f5f5f5',
    greyV2: '#F7F7F7',
    greyV3: '#ECECEC',
    greyV4: '#B5B5B5',
    greyV5: '#FBFBFB',
    greyV6: '#FCFCFC',
  },
  border: {
    default: null,
  },
  severity: {
    success: ['#45d78e', 'rgba(69, 215, 142, 0.2)'],
    info: ['#458ed7', 'rgba(69, 142, 215, 0.2)'],
    warning: ['#d7d745', 'rgba(215, 215, 69, 0.2)'],
    error: ['#d74545', 'rgba(215, 69, 69, 0.2)'],
  },
  footer: {
    background: null,
    text: '#FFFFFF',
  },
  hero: {
    tint: '#0070bb',
  },
  wizard: {
    foreground: null,
  },
  scores: {
    red: ['#f35b53', '#f02e24'],
    yellow: ['#faa442', '#e57d06'],
    green: ['#0a9494', '#087272'],
    text: '#ffffff',
  },
  priceBars: {
    default: '#0a9494',
    dark: '#087070',
    background: '#ECECEC',
  },
  priceBanner: ['#0a9494', '#faa442', '#9953F3'],
  buttons: {
    red: '#f35a53',
    green: '#53f366',
    grey: '#898176',
    hover: null,
    primary: {
      text: '#ffffff',
      boxShadow: 'rgba(0,0,0,0.25)',
    },
  },
  links: {
    default: '#0a9494',
    popularCountries: {
      background: '#f2ffff',
      text: '#0a9494',
      border: '#b5e6e6',
    },
  },
  inputs: {
    border: {
      default: '#c5d0dc',
      greyLight: '#8080800f',
    },
    radio: {
      backgroundChecked: '#63d58b',
      border: '#b9c0c7',
    },
  },
  lightBlue: '#f2fcfc',
  airBalticLogoBackground: '#CDDA32',
  // airBalticFooterBackground: '#252a6e',
  mapMarkers: {
    text: '#ffffff',
  },
  calendarDays: {
    lowest: '#12B76A',
    medium: '#FDB022',
    highest: '#F04438',
  },
  flightOfferAttributes: {
    best: {
      text: '#074a34',
      background: '#d3Fdf0',
    },
    cheapest: {
      text: '#00435c',
      background: '#e0f7ff',
    },
  },
  stars: {
    primary: '#faa340',
  },
};

const fonts = {
  sansSerif: 'Inter, sans-serif',
  displaySubtle: 'Inter, sans-serif',
  display: 'Inter, sans-serif',
  montserrat: 'Inter, sans-serif',
  serif: 'serif',
  fontSize: '62.5%',
};

export const deviceWidths = {
  phone: 480,
  tablet: 768,
  desktop: 1024,
  l: 1280,
  xl: 1700,
  xxl: 2200,
};

export const breakpoints = {
  below: {
    phone: deviceWidths.phone,
    tablet: deviceWidths.tablet,
    desktop: deviceWidths.desktop,
    l: deviceWidths.l,
    xl: deviceWidths.xl,
    xxl: deviceWidths.xxl,
  },
  above: {
    phone: deviceWidths.phone,
    tablet: deviceWidths.tablet,
    desktop: deviceWidths.desktop,
    l: deviceWidths.l,
    xl: deviceWidths.xl,
    xxl: deviceWidths.xxl,
  },
  hotelCard: 767,
};

const containerWidths = {
  phone: '100%',
  tablet: deviceWidths.tablet - 32,
  desktop: deviceWidths.desktop - 32,
};

const theme = {
  colors,
  fonts,
  // For the styled-components-grid library. Which uses 'min-width' for all breakpoints.
  // Therefore 'tiny'-prop for use when 1 or more of mobile, tablet or desktop is also used
  breakpoints: {
    tiny: 0,
    phone: deviceWidths.phone,
    tablet: deviceWidths.tablet,
    desktop: deviceWidths.desktop,
  },
  // For the styled-components-spacing library.
  spacing: {
    0: '0rem',
    0.1: '0.1rem',
    0.25: '0.25rem',
    0.5: '0.5rem',
    0.75: '0.75rem',
    1: '1rem',
    1.5: '1.5rem',
    2: '2rem',
    3: '3rem',
    4: '4rem',
    5: '5rem',
    6: '6rem',
    8: '8rem',
    10: '10rem',
    12: '12rem',
    30: '30px',
  },
  containerWidths,
  touchDevice: false,
};

export default theme;
