import calculateTotalPrice from 'state/checkoutPage/travelSummary/selectors/calculateTotalPrice';
export function getInsurancesSearchRequestParameters(dispatch, state) {
  const { checkoutPagePackagesSearch, checkoutPageTravelSummary, settings } = state;
  const { searchToken } = checkoutPagePackagesSearch.value;
  const { selectedFlights, selectedRooms } = checkoutPageTravelSummary;

  const hotelProductToken = selectedRooms.map((room) => room.productToken).toString();

  const flightProductToken = selectedFlights ? selectedFlights[0] : null;

  const { totalPrice } = dispatch(calculateTotalPrice());
  const { currency } = settings.value.locale;
  const totalPriceCurrency = currency.toUpperCase();

  return { searchToken, hotelProductToken, flightProductToken, totalPrice, totalPriceCurrency };
}

export function getInsuranceByProductToken(insurances, productToken) {
  if (!insurances || !productToken) {
    return null;
  }

  return insurances.find((insurance) => insurance.productToken === productToken);
}

export function getSelectedInsurance(insurances, productToken) {
  if (!insurances || insurances.length === 0) {
    return null;
  }
  if (!productToken) {
    return 'none';
  }

  return insurances.find((insurance) => insurance.productToken === productToken);
}
