import calculateTotalPrice from 'state/checkoutPage/travelSummary/selectors/calculateTotalPrice';
import transformSelectRoomsConfigurations from 'state/selectRooms/transforms/transformSelectRoomsConfigurations';
import getProductId from './utils/getProductId';
import getProductName from './utils/getProductName';
import { getDestinationFullName } from './utils/getProductCategory';
import trackEvent from '../utils/trackEvent';

export default function loadedCheckoutPage(eventName, eventData, state, dispatch) {
  const { hotelPage, hotelPagePackageInfo, deal } = state;
  const hotelInfo = hotelPage.hotelsInfo[0];
  const { current } = hotelPagePackageInfo;
  const { numberOfAdults, numberOfChildren, numberOfInfants } = transformSelectRoomsConfigurations(
    current.search.roomConfigurations
  );
  const { totalPrice } = dispatch(calculateTotalPrice());

  const _eventData = {
    id: getProductId(hotelPage.hotelsInfo, deal),
    sku: getProductId(hotelPage.hotelsInfo, deal),
    name: getProductName(hotelPage.hotelsInfo, deal),
    price: totalPrice,
    quantity: 1,
    category: getDestinationFullName(hotelPage.hotelsInfo),
    // FB params below
    content_type: 'hotel',
    checkin_date: current.search.dates.from,
    checkout_date: current.search.dates.to,
    content_ids: getProductId(hotelPage.hotelsInfo, deal),
    city: hotelInfo.location.city,
    region: hotelInfo.location.city,
    country: hotelInfo.location.country,
    num_adults: numberOfAdults,
    num_children: numberOfChildren + numberOfInfants,
    value: totalPrice,
  };

  trackEvent(eventName, _eventData);
}
